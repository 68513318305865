import React, { Fragment, useEffect, useState } from "react";
import PrimaryButton from "../../../../common/PrimaryButton/PrimaryButton";
import Card from "../../../../common/Card/Card";
import DetailsCardHeader from "../../../../common/PageLayout/components/DetailsCardHeader";
import { AccountDetails } from "./AccountDetails";
import { useAuth } from "../../../../contexts";
import { getAllOrganizationsOfCurrentUser } from "../../../../repositories/userRepository";
import moment from "moment";
import axios from "axios";
import { configs } from "../../../../constants";
import { useNavigate } from "react-router-dom";

function PricingPlanSettings() {
  const { organization: activeOrg, user, allScenarios } = useAuth();
  const [noOfCompanies, setNoOfCompanies] = useState(0);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);
  const [trialHasExpired, setTrialHasExpired] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  const navigate = useNavigate();

  const onPlanChange = (planId: any) => {};

  useEffect(() => {
    (async () => {
      const { organizations } = await getAllOrganizationsOfCurrentUser();
      const response = await axios.post(
        configs.urls.BASE_URL +
          `/invitation/get_all_invitations_info_for_sender`,
        {
          withCredentials: true,
        }
      );
      if (response.data.success === true) {
        const invitationsInfo = response.data.response.invitationsInfo;
        if (invitationsInfo?.length) {
          setInvitedUsers(invitationsInfo?.length);
        }
      }
      if (organizations) {
        setNoOfCompanies(organizations?.length);
      }
    })();
  }, []);

  // useEffect(() => {
  //   console.log("activeOrg >>>>> ", activeOrg);
  // }, [activeOrg]);

  useEffect(() => {
    checkTrialStatus();
  }, [user]);

  const checkTrialStatus = () => {
    if (user && user.trialExpiryDate) {
      const now = moment();
      const userTrialDate = moment(user.trialExpiryDate);

      const timeDiff = userTrialDate.diff(now, "days");

      const daysRemaining = timeDiff >= 0 ? timeDiff : 0;

      console.log(
        `User ${user.id} has ${daysRemaining} days remaining in Pro trial`
      );
      if (daysRemaining <= 0) {
        setTrialHasExpired(true);
      }
      setTrialDaysRemaining(daysRemaining);
    } else {
      setTrialHasExpired(true);
    }
  };

  const handleUpgrade = async (planId: any) => {
    setLoading(true);
    setError(null);

    try {
      // Here you would integrate with your backend API
      // const response = await axios.post('/api/subscription/upgrade', { planId });
      // if (response.data.success) {
      //   onPlanChange(planId);
      // }

      // Simulated success
      setTimeout(() => {
        onPlanChange(planId);
        setLoading(false);
      }, 1000);
    } catch (err) {
      setError("Failed to upgrade plan. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Card className="profile-details-card-container">
        <div className="is-flex is-flex-direction-column is-align-items-center my-4 mx-4">
          <img
            src={"/assets/images/access_stripe.png"}
            alt="connect_bank_account"
            style={{ width: "254px", height: "90px" }}
          />
          <div className="title has-text-centered is-5 mt-6">
            Manage pricing plans
          </div>
          <div className="subtitle is-6 mt-1 has-text-centered">
            Access your dedicated portal to manage all your payment needs
          </div>

          {/* <PrimaryButton
            // className={syncing ? `is-loading` : ""}
            // disabled={syncing || !isSyncingAllowed}
            onClick={() => {
              //open link in new tab
              let link;
              if (process.env.REACT_APP_NODE_ENV === "production") {
                link = "https://billing.stripe.com/p/login/fZe16f6ci9WS8QE3cc";
              } else {
                link =
                  "https://billing.stripe.com/p/login/test_aEU9Eubj2aKRdB63cc";
              }
              window.open(link, "_blank");
            }}
          >
            Access
          </PrimaryButton> */}

          <PrimaryButton
            onClick={() => {
              navigate("/pricing-upgrade");
            }}
          >
            Access
          </PrimaryButton>
        </div>
      </Card>

      <Card className="profile-details-card-container">
        <DetailsCardHeader hasButton={false} title="Account details" />
        <AccountDetails
          selectedProfile={{
            accountType: user?.accountType,
            tier:
              activeOrg?.subscription?.tier.slice(0, 1).toUpperCase() +
              activeOrg?.subscription?.tier.slice(1),
            companiesUnderManagement: noOfCompanies.toString(),
            trialDaysRemaining: trialDaysRemaining.toString(),
            invitedUsers: invitedUsers.toString(),
            noOfScenarios: allScenarios?.length?.toString(),
          }}
          withEditButton={false}
          fetchUser={() => {}}
          trialHasExpired={trialHasExpired}
        />
      </Card>

      {/* <Card className="profile-details-card-container">
        <DetailsCardHeader hasButton={false} title="Account verification" />
        <AccountVerification
          selectedProfile={{
            status: "Not submitted",
          }}
          withEditButton
          fetchUser={() => {}}
        />
      </Card> */}
    </Fragment>
  );
}

export default PricingPlanSettings;
