import React, { useEffect, useState } from "react";
import {
  Card,
  Title,
  Text,
  Group,
  Button,
  Alert,
  SimpleGrid,
  Badge,
  Tabs,
  List,
  Switch,
  Center,
  Container,
} from "@mantine/core";
import {
  BillingPeriod,
  PLANS,
  Tier,
} from "../../../../constants/globalConstants";
import { COLORS } from "../../../../constants/theme";
import { IconCircleCheck } from "@tabler/icons";
import { getUserWithActiveOrganization } from "../../../../repositories/userRepository";
import Loader from "../../../../common/Loader";

function PricingUpgrade() {
  const [billingPeriod, setBillingPeriod] = useState<"monthly" | "yearly">(
    "monthly"
  );
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [organization, setOrganization] = useState<any>();
  const [loading, setLoading] = useState(false);

  // Filter plans based on the selected billing period
  const filteredPlans = PLANS.filter(
    (plan) =>
      (billingPeriod === "monthly" && plan.period === BillingPeriod.Monthly) ||
      (billingPeriod === "yearly" && plan.period === BillingPeriod.Yearly)
  );

  const handleUpgrade = async (planId: string) => {
    if (!planId) {
      alert("Please select a plan to upgrade.");
      return;
    }

    try {
      // Call your backend API to upgrade the plan
      console.log("Upgrading to plan:", planId);
      alert("Plan upgraded successfully!");
      window.location.reload(); // Refresh to reflect changes
    } catch (err: any) {
      console.error("Error upgrading plan:", err);
      alert("Failed to upgrade plan.");
    }
  };

  const getActiveOrganization = async () => {
    setLoading(true);
    const org = await getUserWithActiveOrganization();
    setOrganization(org);
    setLoading(false);
  };

  useEffect(() => {
    getActiveOrganization();
  }, []);

  useEffect(() => {
    console.log("organization .... ", organization);
  }, [organization]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="card-outer-container"
      style={{
        height: "100vh",
        overflowY: "auto",
        padding: "1rem 1rem 5rem 1rem",
      }}
    >
      <Container size="xl" py="xl">
        <Title align="center" order={1} mb="sm">
          Upgrade Your Pricing Plan
        </Title>
        <Text align="center" color="dimmed" mb="xl">
          Choose a plan that best fits your needs.
        </Text>

        {/* Billing Period Toggle */}
        <Center mb="xl">
          <Group>
            <Text>Monthly</Text>
            <Switch
              checked={billingPeriod === "yearly"}
              onChange={(event) =>
                setBillingPeriod(
                  event.currentTarget.checked ? "yearly" : "monthly"
                )
              }
            />
            <Text>Yearly</Text>
            <Badge color="teal" variant="light">
              Save 20%
            </Badge>
          </Group>
        </Center>

        {/* Pricing Cards */}
        <SimpleGrid
          cols={4}
          spacing="xl"
          breakpoints={[
            { maxWidth: "lg", cols: 2 },
            { maxWidth: "md", cols: 1 },
            { maxWidth: "sm", cols: 1 },
          ]}
        >
          {filteredPlans.map((plan: any) => (
            <Card
              key={plan.apiId}
              shadow="sm"
              p="lg"
              radius="md"
              withBorder
              style={{
                border:
                  plan.tier === "Pro"
                    ? `2px solid ${COLORS.greenPrimary}`
                    : "1px solid #ddd",
                cursor: "pointer",
                transform: plan.tier === "Pro" ? "scale(1.05)" : "none",
                transition: "transform 0.2s ease, border-color 0.2s ease",
              }}
              onClick={() => setSelectedPlan(plan.apiId)}
            >
              {plan.tier === "Pro" && (
                <Badge color="blue" variant="light" mb="sm">
                  Recommended
                </Badge>
              )}
              <Title order={3} mb="sm">
                {plan.name}
              </Title>

              <Group spacing={4} mb="xs">
                <Text size={36} weight={700}>
                  {plan.price === 0 ? "Free" : `$${plan.price}`}
                </Text>
                {plan.price !== 0 && (
                  <Text size="sm" color="dimmed" mt={12}>
                    {billingPeriod === "monthly" ? "per month" : "per year"}
                  </Text>
                )}
              </Group>
              <Text size="sm" color="dimmed" mb="md">
                {plan.tier === Tier.Demo ? (
                  <>{"Free forever"}</>
                ) : (
                  <>{"$1 for the first 3 months"}</>
                )}
              </Text>

              {/* Plan Features */}
              <List spacing="sm" size="xs" mb="md">
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  {plan.tier === Tier.Demo ? (
                    <>{"1 Cashflow Scenario"}</>
                  ) : (
                    <>
                      {`Up to ${plan.capabilities.noOfScenarios} cashflow scenarios`}
                    </>
                  )}
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  {plan.tier === Tier.Demo ? (
                    <>{"Up to 6 months of forecasting"}</>
                  ) : (
                    <>
                      {`Up to ${plan.capabilities.scenarioDateRange} year of
                  forecasting`}
                    </>
                  )}
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  {plan.tier === Tier.Starter || plan.tier === Tier.Demo ? (
                    <>{plan.capabilities.noOfUsers} user</>
                  ) : (
                    <>Upto {plan.capabilities.noOfUsers} users</>
                  )}
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  Auto-sync with Xero, Quickbooks, and Zoho Books
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  {plan.tier === Tier.ProPlus ? (
                    <>
                      Unlimited on-demand syncs with Xero, Quickbooks, and Zoho
                      Books
                    </>
                  ) : (
                    <>
                      {plan.capabilities.noOfSyncsPerDay} on-demand syncs with
                      Xero, Quickbooks, and Zoho Books
                    </>
                  )}
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  Bank Reconciliation
                </List.Item>
                <List.Item icon={<IconCircleCheck size={16} color="green" />}>
                  Actual vs Forecast
                </List.Item>
              </List>

              {/* Upgrade Button */}

              <Button
                fullWidth
                size="sm"
                disabled={
                  organization?.subscription?.tier === plan.tier ? true : false
                }
                onClick={() => handleUpgrade(plan.apiId)}
                styles={{
                  root: {
                    backgroundColor: "#35ad8b",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#35ad8b",
                    },
                  },
                }}
              >
                {organization?.subscription?.tier === plan.tier
                  ? "Already Subscribed"
                  : "Upgrade Plan"}
              </Button>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
}

export default PricingUpgrade;
